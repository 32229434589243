<script lang="ts">
	import { discord, twitter, instagram } from '$src/config';
</script>

<div class="flex items-center gap-6">
	{#each [discord, twitter, instagram] as item}
		<a
			href={item.url}
			class="btn hover:bg-base-200 border-0 bg-transparent bg-gradient-to-b from-white/10 to-transparent border-primary-focus shadow-button shadow-primary-focus rounded-[100%] active:shadow-pressed"
			aria-label="social-media-{item.text}"
			target="_blank"
			rel="noopener noreferrer"
		>
			<img
				class="max-h-5 max-w-[20px]"
				width="20"
				height="16"
				src={item.img}
				alt="{item.text} logo"
			/>
		</a>
	{/each}
</div>
