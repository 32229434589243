<script lang="ts">
	import '@fontsource/work-sans/500.css';
	import '@fontsource/work-sans/600.css';
	import '@fontsource/work-sans/700.css';
	import '@fontsource/montserrat/500.css';
	import '@fontsource/montserrat/600.css';
	import '@fontsource/montserrat/700.css';
	import '@fontsource/dm-mono';
	import '$src/app.css';
	import NavBar from '$src/components/NavBar.svelte';
	import Footer from '$src/components/Footer.svelte';
	import TopButton from '$src/components/TopButton.svelte';
</script>

<svelte:head>
	<script>
		(function (m, a, i, l, e, r) {
			m['MailerLiteObject'] = e;
			function f() {
				var c = { a: arguments, q: [] };
				var r = this.push(c);
				return 'number' != typeof r ? r : f.bind(c.q);
			}
			f.q = f.q || [];
			m[e] = m[e] || f.bind(f.q);
			m[e].q = m[e].q || f.q;
			r = a.createElement(i);
			var _ = a.getElementsByTagName(i)[0];
			r.async = 1;
			r.src = l + '?v' + ~~(new Date().getTime() / 1000000);
			_.parentNode.insertBefore(r, _);
		})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');

		var ml_account = ml('accounts', '3570149', 'u6q2w6g8i7', 'load');
	</script>
	<!-- Google tag (gtag.js) -->
	<script async src="https://www.googletagmanager.com/gtag/js?id=G-LFVGT82HNG"></script>
	<script>
		window.dataLayer = window.dataLayer || [];
		function gtag() {
			dataLayer.push(arguments);
		}
		gtag('js', new Date());

		gtag('config', 'G-LFVGT82HNG');
	</script>
</svelte:head>

<NavBar />

<main class="flex flex-col">
	<slot />

	<Footer />
	<TopButton />
</main>
