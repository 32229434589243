<script lang="ts">
	import { page } from '$app/stores';
	import { fly } from 'svelte/transition';
	import Hamburger from './Hamburger.svelte';
	import MediaButtons from './MediaButtons.svelte';
	import { home, products, photographyPilot, aboutUs, discord } from '$src/config';

	const internalLinks = [home, products, photographyPilot, aboutUs];

	/**
	 * state that sets if the sidebar is hidden or visible
	 */
	let sideBar = false;
</script>

<div class="sticky top-0 z-50 w-full">
	<div class="navbar relative font-montserrat backdrop-blur-sm bg-base-100/90 backdrop-filter-blur">
		<div class="container xl:max-w-7xl px-4 md:px-8 lg:px-12">
			<button
				class="block md:hidden absolute"
				on:click={() => (sideBar = !sideBar)}
				aria-label="menu"
			>
				<Hamburger open={sideBar} />
			</button>
			<div class="flex-1 flex justify-center md:block">
				<a class="normal-case text-xl py-1" href={home.url}>
					<img
						class="h-16"
						height="64"
						width="151"
						src="/kelp_logo.svg"
						alt="blue logo on white background"
					/>
				</a>
			</div>
			<div class="hidden md:flex items-center md:gap-4 lg:gap-6">
				{#each internalLinks as link}
					<a
						class="content hover:border-b hover:border-primary"
						class:text-info={link.url !== $page.url.pathname}
						class:text-accent={link.url === $page.url.pathname}
						class:font-bold={link.url === $page.url.pathname}
						href={link.url}
					>
						{link.text}
					</a>
				{/each}
				<a
					class="textGradient font-bold hover:border-b hover:border-primary font-workSans text-lg"
					href={discord.url}
					target="_blank"
					rel="noreferrer"
				>
					Community
				</a>
			</div>
		</div>
	</div>
	<div class="h-0.5 bg-gradient-to-r from-secondary-content to-primary" />
	{#if sideBar}
		<div
			class="absolute flex h-screen w-full bg-base-100 text-lg"
			in:fly|local={{ x: -300, duration: 500 }}
			out:fly|local={{ x: -300, duration: 500 }}
		>
			<div class="flex-grow flex flex-col items-center py-8 gap-8">
				{#each internalLinks as link}
					<a
						href={link.url}
						class="content hover:border-b hover:border-primary"
						class:text-info={link.url !== $page.url.pathname}
						class:text-accent={link.url === $page.url.pathname}
						class:font-bold={link.url === $page.url.pathname}
						on:click={() => (sideBar = false)}
					>
						{link.text}
					</a>
				{/each}
				<a
					class="textGradient font-bold hover:border-b hover:border-primary font-workSans text-lg"
					href={discord.url}
					target="_blank"
					rel="noreferrer"
					on:click={() => (sideBar = false)}
				>
					Community
				</a>
				<div class="pt-20">
					<MediaButtons />
				</div>
			</div>
		</div>
	{/if}
</div>
